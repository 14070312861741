/* ONLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.welcome_title", "Besoin d'aide ?");
olark.configure("locale.chatting_title", "Aide en direct : Conversation en cours");
olark.configure("locale.welcome_message", "Bienvenu(e), veuillez renseigner les champs ci-dessous puis cliquez sur 'Commencer à chatter' ou consultez la rubrique <a href='https://www.lordgunbicycles.fr/aide/faq' target='_blank'>FAQ</a>");
olark.configure("locale.chat_input_text", "Tapez votre texte ici et cliquez sur Envoyer pour commencer à dialoguer");
olark.configure("locale.ended_chat_message", "Cette conversation est terminée.");
olark.configure("locale.end_chat_button_text", "Terminer la conversation");

/* OFFLINE CHATBOX custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.unavailable_title", "Besoin d'aide ?");
olark.configure("locale.away_message", "Tous nos opérateurs sont actuellement indisponibles, si vous avez besoin d’aide merci de remplir le formulaire via la page <a href='https://www.lordgunbicycles.fr/aide#contactUs' target='_blank'>aide</a>");
olark.configure("locale.name_input_text", "Entrez votre nom et prénom");
olark.configure("locale.email_input_text", "Entrez votre adresse e-mail");
olark.configure("locale.phone_input_text", "Entrez votre numéro de téléphone");
olark.configure("locale.offline_note_message", "Nous sommes actuellement hors ligne, veuillez nous laisser un message");
olark.configure("locale.send_button_text", "Envoyer");
olark.configure("locale.offline_note_sending_text", "En cours d'envoi...");
olark.configure("locale.offline_note_thankyou_text", "Merci pour votre message. Nous vous contacterons dès que possible.");
olark.configure("locale.offline_note_error_text", "Remplissez tous les champs et insérez une adresse e-mail valide.");

/* PRE-CHAT SURVEY custom configuration goes here (www.olark.com/documentation) */
olark.configure("locale.name_input_text", "Entrez votre nom et prénom");
olark.configure("locale.email_input_text", "Entrez votre adresse e-mail");
olark.configure("locale.phone_input_text", "Entrez votre numéro de téléphone");
olark.configure("locale.introduction_error_text", "Remplissez tous les champs et insérez une adresse e-mail valide.");
olark.configure("locale.introduction_messages", "Bienvenu(e), veuillez renseigner les champs ci-dessous puis cliquez sur 'Commencer à chatter' ou consultez la rubrique <a href='https://www.lordgunbicycles.fr/aide/faq' target='_blank'>FAQ</a>");
olark.configure("locale.introduction_submit_button_text", "Commencer à chatter");
olark.configure("locale.prechat_survey_name_label", "Nom");
olark.configure("locale.prechat_survey_name_placeholder", "Nom");

/* CHAT RATINGS custom configuration goes here (www.olark.com/documentation) */
olark.configure('locale.feedback_survey_question_chat_text', 'Assistance reçue');
olark.configure('locale.feedback_survey_question_operator_intelligence_text', "Niveau de compétence de l'opérateur");
olark.configure('locale.feedback_survey_question_operator_speed_text', "Rapidité de l'opérateur");
olark.configure('locale.feedback_survey_question_operator_attitude_text', "Amabilité de l'opérateur");
olark.configure('locale.feedback_survey_question_1_text', 'Évaluation 1 sur 5');
olark.configure('locale.feedback_survey_question_2_text', 'Évaluation 2 sur 5');
olark.configure('locale.feedback_survey_question_3_text', 'Évaluation 3 sur 5');
olark.configure('locale.feedback_survey_question_4_text', 'Évaluation 4 sur 5');
olark.configure('locale.feedback_survey_question_5_text', 'Évaluation 5 sur 5');
olark.configure('locale.feedback_survey_question_chat_low', 'Très mécontent(e)');
olark.configure('locale.feedback_survey_question_chat_high', 'Très satisfait(e)');
olark.configure('locale.feedback_survey_question_operator_intelligence_low', 'Pas compétent du tout');
olark.configure('locale.feedback_survey_question_operator_intelligence_high', 'Très compétent');
olark.configure('locale.feedback_survey_question_operator_speed_low', 'Très lent');
olark.configure('locale.feedback_survey_question_operator_speed_high', 'Très rapide');
olark.configure('locale.feedback_survey_question_operator_attitude_low', 'Pas aimable du tout');
olark.configure('locale.feedback_survey_question_operator_attitude_high', 'Très aimable');
olark.configure('locale.feedback_survey_question_additional_feedback_text', 'Ajouter un commentaire');
olark.configure('locale.feedback_survey_button_next', 'Suivante');
olark.configure('locale.feedback_survey_button_submitting', 'Envoyer');
olark.configure('locale.feedback_survey_button_finish', 'Fin');
olark.configure('locale.feedback_survey_end_message', 'Merci pour votre commentaire');
olark.configure('locale.feedback_survey_submission_error_message', 'Une erreur est survenue lors de l’envoi de votre question, veuillez réessayer plus tard.');
olark.configure('locale.feedback_survey_begin_button_text', "Évaluer l'assistance reçue");
olark.configure('locale.feedback_survey_complete_button_text', 'Fin');
olark.configure('locale.feedback_survey_cancel_text', 'Annuler');
olark.configure('locale.restart_chat_online_button_text', 'Entrez votre message ici');
olark.configure('locale.restart_chat_offline_button_text', 'Email');

/* SEND TRANSCRIPTS custom configuration goes here */
olark.configure('locale.send_transcript_title_text', 'Envoyer la transcription');
olark.configure('locale.send_transcript_begin_button_text', 'Envoyer la transcription');
olark.configure('locale.send_transcript_complete_button_text', 'Transcription envoyée');
olark.configure('locale.send_transcript_error_text', 'Une erreur est survenue lors de l’envoi de la transcription');
olark.configure('locale.send_transcript_cancel_text', 'Annuler');
olark.configure('locale.send_transcript_placeholder', 'Entrez votre adresse e-mail');

/* OPERATOR custom configuration goes here */
olark.configure("locale.operator_is_typing_text", "écrit...");
olark.configure("locale.operator_has_stopped_typing_text", "a cessé d'écrire");

olark.configure("locale.disabled_input_text_when_convo_has_ended", "Conversation terminée, veuillez actualiser la page pour débuter une nouvelle session");
olark.configure("locale.disabled_panel_text_when_convo_has_ended", "Cette conversation est terminée. Pour en commencer une nouvelle, veuillez actualiser la page.");